import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth"
import { getFirebase } from "../../../services/firebase/firebase"

export const sendOTP = async ({ mobileNumber }) => {
  try {
    const app = getFirebase() // Ensure Firebase app is initialized
    const auth = getAuth(app) // Get Firebase auth instance

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      )
    }

    const confirmationResult = await signInWithPhoneNumber(
      auth,
      mobileNumber,
      window.recaptchaVerifier
    )
    return confirmationResult
  } catch (error) {
    console.error("Error sending OTP:", error)
    throw error
  }
}
